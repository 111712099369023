.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--clr-primary-100);
  min-height: 26rem;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__link {
    a {
      display: block;
      border: 1px solid var(--clr-primary-400);
      padding-top: 2.5rem;
      padding-right: 4.8rem;
      padding-bottom: 2.5rem;
      padding-left: 4.8rem;
      font-weight: 500;
      text-decoration: underline;
      color: var(--clr-default-200);
      transition: box-shadow var(--transition);

      &:hover {
        box-shadow: 1px 1px 35px rgba(252, 226, 71, 0.35);
        transition: box-shadow var(--transition);
      }
    }
  }
}
