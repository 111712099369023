/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

.input-reset::-webkit-search-decoration,
.input-reset::-webkit-search-cancel-button,
.input-reset::-webkit-search-results-button,
.input-reset::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Montserrat", sans-serif;
  --font-weight: 400;
  --content-width: 117rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: rgba(255, 255, 255, 0.9);
  --clr-default-800: #111;
  --clr-default-900: #000;
  --clr-primary-100: rgba(255, 255, 255, 0.15);
  --clr-primary-400: #FCE247;
  --clr-primary-600: #07512F;
  --clr-primary-700: #096137;
}

body {
  font-size: 1.6rem;
  color: var(--clr-default-200);
  background-color: var(--clr-default-800);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

/* stylelint-disable no-invalid-position-at-import-rule */

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.title {
  font-weight: 700;
  font-size: clamp(
    3.6rem,
    2.7272727273rem + 2.7272727273vw,
    6rem
  );
  line-height: 115%;
  color: var(--clr-primary-400);
}

.subtitle {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 145%;
}

.main__link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  min-width: 30rem;
  min-height: 7rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--clr-default-800);
  background: linear-gradient(180deg, #FCE247 0%, #FF9C19 100%);
  transition: box-shadow var(--transition);
}

.main__link:hover {
  box-shadow: 1px 1px 35px rgba(252, 226, 71, 0.35);
  transition: box-shadow var(--transition);
}

.banner {
  margin: 6rem auto;
  max-width: 97rem;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item {
  position: relative;
}

.menu__item::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--clr-primary-400);
  transform: translate(-50%, 0);
  transition: width 0.3s;
}

.menu__item:hover::before {
  width: 100%;
  transition: width 0.3s;
}

.menu__item a {
  font-size: 1.4rem;
  color: var(--clr-default-200);
}

.menu__item a:hover {
  color: var(--clr-primary-400);
  transition: color 0.3s;
}

.menu__item:not(:last-child) {
  margin-right: 4rem;
}

.logo {
  margin-right: 1.5rem;
}

.burger {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--clr-primary-100);
  min-height: 9rem;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--clr-primary-100);
  min-height: 26rem;
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__link a {
  display: block;
  border: 1px solid var(--clr-primary-400);
  padding-top: 2.5rem;
  padding-right: 4.8rem;
  padding-bottom: 2.5rem;
  padding-left: 4.8rem;
  font-weight: 500;
  text-decoration: underline;
  color: var(--clr-default-200);
  transition: box-shadow var(--transition);
}

.footer__link a:hover {
  box-shadow: 1px 1px 35px rgba(252, 226, 71, 0.35);
  transition: box-shadow var(--transition);
}

.hero {
  position: relative;
}

.hero__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 7.6rem;
}

.hero__img {
  margin-right: 3rem;
}

.hero__img::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  border-radius: 1.6rem;
  width: calc(50vw - 11rem);
  height: 41.5rem;
  background-color: var(--clr-primary-600);
  transform: translateY(-45.2%);
}

.hero__text {
  max-width: 57rem;
}

.hero__title {
  padding-bottom: 1.6rem;
}

.hero__subtitle {
  padding-bottom: 5rem;
  color: var(--clr-default-100);
}

.hero__bottom {
  display: grid;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
}

.hero__item-title {
  padding-bottom: 1.6rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 100%;
  color: var(--clr-primary-400);
}

.hero__description {
  line-height: 135%;
  color: var(--clr-default-100);
}

.info {
  padding-top: 14rem;
}

.info__title {
  padding-bottom: 6rem;
}

.info__table {
  overflow: auto;
  border-radius: 1.6rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  padding-left: 2.4rem;
  padding-right: 3.6rem;
  background-color: var(--clr-primary-600);
}

.info__scroll-wrap {
  min-width: 42rem;
}

.info__item {
  display: grid;
  grid-column-gap: 3rem;
  grid-template-columns: 28% 69%;
}

.info__item:not(:last-child) {
  margin-bottom: 1.6rem;
  border-bottom: 1px solid #096137;
  padding-bottom: 1.6rem;
}

.info__item p {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 140%;
}

.info__item p:not(:last-child) {
  color: var(--clr-default-100);
}

.info__link {
  word-break: break-all;
  color: var(--clr-default-200);
}

.benefits {
  padding-top: 14rem;
  padding-bottom: 14rem;
  background-color: var(--clr-default-100);
}

.benefits__title-wrapper {
  padding-bottom: 2.4rem;
  font-size: clamp(
    4rem,
    3.2727272727rem + 2.2727272727vw,
    6rem
  );
  line-height: 125%;
  color: var(--clr-default-800);
}

.benefits__description {
  margin-left: auto;
  padding-bottom: 6rem;
  max-width: 56rem;
  line-height: 145%;
  color: rgba(0, 0, 0, 0.9);
}

.benefits__wrapper {
  display: grid;
  grid-column-gap: 3rem;
  grid-template-columns: 65% 31%;
}

.benefits__item-wrap {
  position: relative;
  padding-top: 23rem;
}

.benefits__item-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 1.6rem;
  width: 100%;
  height: 23.3rem;
  background-position: center;
  background-size: 12.5rem 12.5rem;
  background-repeat: no-repeat;
  background-color: var(--clr-primary-700);
}

.benefits__item {
  margin-top: 3.2rem;
  color: var(--clr-default-900);
}

.benefits__item--pros::before {
  background-image: url(../img/mobile/pros-smile.svg);
}

.benefits__item--pros::after {
  content: "";
  position: absolute;
  left: -20.8rem;
  top: -12.6rem;
  width: 34.6rem;
  height: 34.5rem;
  background-image: url(../img/mobile/card.png);
}

.benefits__item--cons::before {
  background-image: url(../img/mobile/cons-smile.svg);
}

.benefits__list {
  position: relative;
  padding-left: 3.6rem;
  font-weight: 500;
  font-size: clamp(
    1.6rem,
    1.4545454545rem + 0.4545454545vw,
    2rem
  );
  line-height: 120%;
}

.benefits__list::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  background-position: center;
  background-repeat: no-repeat;
}

.benefits__list--pros::before {
  background-image: url(../img/mobile/pros.svg);
  background-color: var(--clr-primary-600);
}

.benefits__list--cons::before {
  background-image: url(../img/mobile/cons.svg);
  background-color: #666;
}

.benefits__list:not(:last-child) {
  margin-bottom: 2rem;
}

.app {
  padding-top: 14rem;
}

.app__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app__content {
  margin-right: 3rem;
  max-width: 57rem;
}

.app__title {
  padding-bottom: 2.5rem;
}

.register {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.register__title {
  padding-bottom: 2.5rem;
  word-break: break-word;
}

.register__subtitle {
  padding-bottom: 7.8rem;
  max-width: 57rem;
}

.register__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6rem;
  padding-left: 19.5rem;
  min-height: 17rem;
  max-width: 77rem;
}

.register__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  border-radius: 0.8rem;
  width: 17rem;
  height: 17rem;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--clr-primary-700);
  transform: translateY(-50%);
}

.register__item--up::before {
  background-image: url(../img/register/up.svg);
}

.register__item--deposit::before {
  background-image: url(../img/register/deposit.svg);
}

.register__item--play::before {
  background-image: url(../img/register/play.svg);
}

.register__item--get::before {
  background-image: url(../img/register/get.svg);
}

.register__item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 7.5rem;
  bottom: -4.5rem;
  width: 2.6rem;
  height: 3.6rem;
  background-image: url(../img/register/arrow.svg);
  background-repeat: no-repeat;
}

.register__item-title {
  padding-bottom: 1.2rem;
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.1818181818rem + 1.9318181818vw,
    3.5rem
  );
}

.register__item-subtitle {
  line-height: 145%;
}

.register__btn {
  display: flex;
  justify-content: center;
}

.games {
  padding-top: 14rem;
  padding-bottom: 14rem;
  background-color: var(--clr-primary-600);
}

.games__title {
  padding-bottom: 2.5rem;
}

.games__text-wrap {
  padding-bottom: 6rem;
  line-height: 145%;
}

.games__text-wrap p:not(:last-child) {
  margin-bottom: 2rem;
}

.games__items {
  display: grid;
  grid-gap: 2.6rem;
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
}

.games__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 1.6rem;
  min-height: 43rem;
  background-color: var(--clr-default-800);
}

.games__img {
  position: relative;
  padding-bottom: 67%;
  width: 100%;
}

.games__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.games__item-title {
  padding-bottom: 1.5rem;
  font-size: clamp(
    1.8rem,
    1.6545454545rem + 0.4545454545vw,
    2.2rem
  );
  text-align: center;
}

.games__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.games__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1.6rem;
  padding-right: 1.6rem;
  padding-bottom: 2.5rem;
  padding-left: 1.6rem;
  height: 100%;
}

.games__name {
  padding-bottom: 0.8rem;
  font-size: clamp(
    1.6rem,
    1.5272727273rem + 0.2272727273vw,
    1.8rem
  );
}

.games__cost {
  padding-bottom: 1.6rem;
  font-size: clamp(
    1.6rem,
    1.5272727273rem + 0.2272727273vw,
    1.8rem
  );
  color: var(--clr-primary-400);
}

.games__link {
  margin: 0 auto;
  margin-bottom: 1.6rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  min-width: 17.5rem;
  min-height: 5rem;
}

.games__text {
  color: #c6c6c6;
}

.available {
  padding-top: 14rem;
}

.available__title {
  padding-bottom: 2.5rem;
}

.available__table {
  overflow: auto;
  margin-top: 6rem;
  background-color: var(--clr-primary-700);
}

.available__scroll-wrap {
  min-width: 54rem;
}

.available__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.available__wrapper p {
  font-weight: 500;
  line-height: 125%;
}

.available__wrapper:not(:first-child, :last-child) {
  border-bottom: 1px solid var(--clr-primary-100);
}

.available__item {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  padding-left: 2.4rem;
  padding-right: 2rem;
}

.available__item--head {
  padding-top: 2.3rem;
  padding-bottom: 2.3rem;
  padding-left: 1.6rem;
  font-weight: 700;
  background-color: var(--clr-primary-600);
}

.available__item:not(:last-child) {
  border-right: 1px solid var(--clr-primary-100);
}

.available__list {
  list-style: disc;
  padding-left: 2rem;
}

.available__list li {
  font-weight: 500;
}

.available__list li:not(:last-child) {
  margin-bottom: 1.6rem;
}

.live__title {
  padding-bottom: 2.5rem;
}

.live__subtitle {
  padding-bottom: 5rem;
}

.live__items {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
}

.live__item {
  position: relative;
  overflow: hidden;
  border-radius: 0.4rem;
  padding-bottom: 100%;
  transition: filter var(--transition);
}

.live__item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform var(--transition);
}

.live__item img:hover {
  transform: scale(1.02);
  transition: transform var(--transition);
}

.live__item:hover {
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.15));
  transition: filter var(--transition);
}

.live__link {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
}

.bonus {
  position: relative;
  padding-top: 6.5rem;
}

.bonus__title {
  padding-bottom: 2rem;
}

.bonus__subtitle {
  margin-bottom: 5rem;
}

.bonus__wrapper {
  position: relative;
}

.bonus__wrapper::after {
  content: "";
  position: absolute;
  left: -25rem;
  top: 8rem;
  width: 18.4rem;
  height: 17.4rem;
  background-image: url(../img/bonus/chip-left.svg);
  background-repeat: no-repeat;
  filter: blur(3px);
}

.bonus__items {
  position: relative;
  display: grid;
  grid-gap: 3rem 4rem;
  grid-template-columns: repeat(2, 1fr);
  max-width: 76rem;
  padding-bottom: 4.5rem;
}

.bonus__items::before,
.bonus__items::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
}

.bonus__items::before {
  right: -40.8rem;
  bottom: 0;
  width: 27.6rem;
  height: 35.9rem;
  background-image: url(../img/bonus/chips.svg);
  background-size: 27.6rem 35.9rem;
}

.bonus__items::after {
  right: -36rem;
  top: 8rem;
  width: 13rem;
  height: 12rem;
  background-image: url(../img/bonus/chip-right.svg);
  filter: blur(2px);
}

.bonus__item-title {
  padding-bottom: 0.8rem;
  font-weight: 700;
  font-size: clamp(
    1.8rem,
    1.5818181818rem + 0.6818181818vw,
    2.4rem
  );
  color: var(--clr-primary-400);
}

.bonus__item-subtitle {
  line-height: 145%;
}

.bonus__link-wrap {
  margin-top: auto;
}

.bonus__table {
  margin-top: 0;
}

.vip {
  padding-top: 14rem;
}

.vip__title {
  padding-bottom: 2.5rem;
}

.vip__wrapper p {
  font-weight: initial;
}

.vip__item p {
  font-weight: 500;
}

.vip__item--head p {
  font-weight: 500;
}

.license {
  padding-top: 14rem;
}

.license__title {
  padding-bottom: 2.5rem;
}

.license__subtitle {
  font-weight: 400;
  font-size: 1.6rem;
}

.license__subtitle span {
  color: var(--clr-primary-400);
}

.license__link {
  margin-top: 6rem;
}

.faq__title {
  padding-bottom: 6rem;
}

.faq__body {
  margin-bottom: 4rem;
}

.faq__wrapper:not(:last-child) {
  margin-bottom: 2rem;
}

.faq__wrapper.active .faq__question::before {
  height: 0;
  transition: height var(--transition);
}

.faq__wrapper.active .faq__answer {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  transition: max-height var(--transition), padding var(--transition);
}

.faq__question {
  position: relative;
  padding-top: 2.8rem;
  padding-right: 8rem;
  padding-bottom: 2.8rem;
  padding-left: 2.5rem;
  min-width: 100%;
  font-weight: 700;
  font-size: 1.8rem;
  text-align: start;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-600);
}

.faq__question::before,
.faq__question::after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 0.3rem;
  background-color: var(--clr-primary-400);
  transform: translateY(-50%);
  transition: height var(--transition);
}

.faq__question::before {
  right: 3.5rem;
  width: 0.2rem;
  height: 2rem;
}

.faq__question::after {
  right: 2.6rem;
  width: 2rem;
  height: 0.2rem;
}

.faq__answer {
  overflow: hidden;
  padding-top: 0;
  padding-right: 1rem;
  padding-bottom: 0;
  padding-left: 2.5rem;
  max-height: 0;
  box-sizing: content-box;
  font-size: 1.8rem;
  line-height: 150%;
  transition: max-height var(--transition), padding var(--transition);
}

.faq__table {
  margin-bottom: 3rem;
}

.faq__text {
  line-height: 145%;
}

.faq__text a {
  color: var(--clr-primary-400);
}

.conclusion {
  padding-top: 14rem;
}

.conclusion__title {
  padding-bottom: 2.5rem;
}

.conclusion__text {
  padding-bottom: 6rem;
}

.conclusion__text p:not(:last-child) {
  margin-bottom: 1.2rem;
}

@media (max-width: 75em) {
  .menu__item:not(:last-child) {
    margin-right: 3rem;
  }

  .hero__text {
    max-width: 50rem;
  }

  .app__content {
    margin-right: 0;
    max-width: initial;
  }

  .app__img {
    display: none;
  }

  .bonus {
    padding-top: 10rem;
  }

  .bonus__wrapper {
    margin-left: 0;
  }

  .bonus__wrapper::after,
  .bonus__wrapper::before {
    display: none;
  }

  .bonus__items::before {
    display: none;
  }

  .bonus__items {
    max-width: initial;
  }

  .bonus__items::after {
    display: none;
  }
}

@media (max-width: 62em) {
  .banner {
    margin: 3rem auto;
  }

  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item a {
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-default-200);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .hero__top {
    padding-top: 5rem;
  }

  .hero__img {
    display: none;
  }

  .hero__text {
    max-width: initial;
  }

  .info {
    padding-top: 10rem;
  }

  .benefits {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .benefits__item--pros::after {
    display: none;
  }

  .app {
    padding-top: 10rem;
  }

  .register {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .games {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .games__items {
    grid-gap: 1.5rem;
  }

  .available {
    padding-top: 10rem;
  }

  .vip {
    padding-top: 10rem;
  }

  .license {
    padding-top: 10rem;
  }

  .conclusion {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

@media (max-width: 48em) {
  .hero__bottom {
    grid-template-columns: 1fr;
    padding-bottom: 5rem;
  }

  .info {
    padding-top: 3rem;
  }

  .benefits {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .benefits__wrapper {
    display: block;
  }

  .benefits__item--pros {
    margin-bottom: 3rem;
  }

  .app {
    padding-top: 3rem;
  }

  .register {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .register__subtitle {
    padding-bottom: 4rem;
  }

  .games {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .available {
    padding-top: 3rem;
  }

  .available__table {
    margin-top: 4rem;
  }

  .available__list {
    padding-left: 1.2rem;
  }

  .bonus {
    padding-top: 3rem;
  }

  .bonus__items {
    display: block;
  }

  .bonus__item:not(:last-child) {
    margin-bottom: 2rem;
  }

  .vip {
    padding-top: 3rem;
  }

  .license {
    padding-top: 3rem;
  }

  .license__link {
    margin-top: 3rem;
  }

  .conclusion {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .conclusion__text {
    padding-bottom: 4rem;
  }
}

@media (max-width: 36em) {
  .info__table {
    padding-right: 2rem;
    padding-left: 1.5rem;
  }

  .benefits__description {
    padding-bottom: 4rem;
  }
}

@media (max-width: 98.125em) {
  .benefits__item--pros::after {
    left: -11rem;
  }
}

@media (max-width: 71.25em) {
  .app__wrapper {
    display: block;
  }
}

@media (max-width: 35.9375em) {
  .info__item {
    grid-column-gap: 1.5rem;
  }

  .register__item::before {
    width: 7rem;
    height: 7rem;
    background-size: 5rem 50rem;
  }

  .register__item {
    margin-bottom: 3rem;
    padding-left: 8.5rem;
    min-height: 12rem;
  }

  .register__item:not(:last-child)::after {
    display: none;
  }

  .available__item {
    padding-left: 1.2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-right: 1.2rem;
  }

  .available__list li:not(:last-child) {
    margin-bottom: 1rem;
  }

  .live__link {
    margin-top: 3rem;
    min-width: 25rem;
  }

  .bonus__table {
    margin-top: 0;
  }

  .faq__title {
    padding-bottom: 3rem;
  }

  .faq__wrapper.active .faq__answer {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }

  .faq__question::before {
    right: 2.5rem;
  }

  .faq__question::after {
    right: 1.6rem;
  }

  .faq__question {
    padding-top: 1.8rem;
    padding-right: 5rem;
    padding-bottom: 1.8rem;
    padding-left: 1.5rem;
  }

  .faq__answer {
    padding-left: 1.5rem;
  }
}