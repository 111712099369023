.info {
  padding-top: 14rem;

  @media (max-width: em(992)) {
    padding-top: 10rem;
  }

  @media (max-width: em(768)) {
    padding-top: 3rem;
  }

  &__title {
    padding-bottom: 6rem;
  }

  &__table {
    overflow: auto;
    border-radius: 1.6rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 2.4rem;
    padding-right: 3.6rem;
    background-color: var(--clr-primary-600);

    @media (max-width: em(576)) {
      padding-right: 2rem;
      padding-left: 1.5rem;
    }
  }

  &__scroll-wrap {
    min-width: 42rem;
  }

  &__item {
    display: grid;
    grid-column-gap: 3rem;
    grid-template-columns: 28% 69%;

    &:not(:last-child) {
      margin-bottom: 1.6rem;
      border-bottom: 1px solid #096137;
      padding-bottom: 1.6rem;
    }

    p {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 140%;

      &:not(:last-child) {
        color: var(--clr-default-100);
      }
    }

    @media (max-width: em(575)) {
      grid-column-gap: 1.5rem;
    }
  }

  &__link {
    word-break: break-all;
    color: var(--clr-default-200);
  }
}
