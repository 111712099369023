.live {

  &__title {
    padding-bottom: 2.5rem;
  }

  &__subtitle {
    padding-bottom: 5rem;
  }

  &__items {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  }

  &__item {
    position: relative;
    overflow: hidden;
    border-radius: 0.4rem;
    padding-bottom: 100%;
    transition: filter var(--transition);

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: transform var(--transition);

      &:hover {
        transform: scale(1.02);
        transition: transform var(--transition);
      }
    }

    &:hover {
      filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.15));
      transition: filter var(--transition);
    }
  }

  &__link {
    margin-top: 6rem;
    display: flex;
    justify-content: center;

    @media (max-width: em(575)) {
      margin-top: 3rem;
      min-width: 25rem;
    }
  }
}
